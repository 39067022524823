import './menu.scss';
// import {
//   del,
//   update,
//   add,
//   getList,
//   getTree
// } from '@/api/menu.js';
import dataApi from '@/api/system';
import iconList from '@/utils/iconDataList';
import menuApi from '@/api/system/menu.js';

const {
  del,
  update,
  add,
  getList,
  getTree
} = menuApi;

export default {
  name: 'menu',
  components: {},
  data () {
    return {
      dataDic: [],
      formDic: {},
      data: [],
      filterText: '',
      form: {
        menu_id: 0,
        menu_type: 'M',
        parent_id: 0,
        is_cache: 1,
        status: 0,
        visible: 0,
        is_frame: 1
      },
      formOp: {
        column: [
          { // 上级菜单
            type: 'tree',
            label: '上级菜单',
            span: 24,
            dicData: [],
            props: {
              label: 'menu_name',
              value: 'menu_id'
            },
            parent: true,
            prop: 'parent_id'
          },
          { // 菜单类型
            type: 'radio',
            label: '类型',
            value: 'M',
            dicData: [
              {
                label: '目录',
                value: 'M'
              },
              {
                label: '菜单',
                value: 'C'
              },
              {
                label: '按钮',
                value: 'F'
              }
            ],
            span: 24,
            rules: [{
              required: true,
              message: '请选择菜单类型',
              trigger: 'blur'
            }],
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'menu_type'
          },
          { // 菜单名称
            type: 'input',
            label: '名称',
            span: 24,
            rules: [{
              required: true,
              message: '请输入菜单名称',
              trigger: 'blur'
            }],
            display: true,
            prop: 'menu_name'
          },
          { // 路由地址
            type: 'input',
            label: '路由地址',
            span: 24,
            rules: [{
              required: true,
              message: '请输入路由地址',
              trigger: 'blur'
            }],
            display: false,
            prop: 'path'
          },
          { // 资源地址
            type: 'tree',
            filterText: '搜索关键字制自定义',
            multiple: true,
            props: {
              label: 'name',
              value: 'resource_id'
            },
            label: '资源路径',
            span: 24,
            leafOnly: false,
            dataType: 'number',
            display: false,
            prop: 'resource_id'
          },
          { // 权限标识
            type: 'input',
            label: '权限标识',
            value: 'admin',
            span: 12,
            rules: [{
              required: true,
              message: '请输入路由地址',
              trigger: 'blur'
            }],
            display: false,
            prop: 'perms'
          },
          { // 组件路径
            type: 'input',
            label: '组件路径',
            span: 12,
            value: '/',
            display: false,
            prop: 'component'
          },
          { // 菜单图标
            label: '图标',
            prop: 'icon',
            type: 'icon',
            span: 12,
            iconList: iconList
          },
          { // 是否是外连接
            label: '是否外连接',
            type: 'radio',
            dicData: [
              {
                label: '是',
                value: 0
              },
              {
                label: '否',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: false,
            prop: 'is_frame'
          },
          { // 排序
            type: 'number',
            value: 0,
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting'
          },
          { // 菜单状态
            label: '菜单状态',
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '禁用',
                value: 1
              },
              {
                label: '启用',
                value: 0
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'status'
          },
          { // 显示状态
            label: '显示状态',
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '显示',
                value: 0
              },
              {
                label: '隐藏',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'visible'
          },
          { // 是否缓存
            label: '是否缓存',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '缓存',
                value: 0
              },
              {
                label: '不缓存',
                value: 1
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: false,
            prop: 'is_cache'
          },
          { // 菜单说明
            type: 'input',
            label: '备注',
            span: 24,
            display: true,
            prop: 'remark'
          }
        ],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 100,
        gutter: 0,
        menuSpan: 24,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      defaultProps: {
        children: 'children',
        label: 'menu_name'
      },
      submittype: 0
    };
  },
  computed: {},
  mounted () {
    console.log({
      del,
      update,
      add,
      getList,
      getTree
    }, 'menuApi');
    // this.getTreeList();
    console.log('!@#$%^$#@$%^$#@$%^$#@$%', iconList);
    this.getList();
  },
  filters: {},
  watch: {
    'form.parent_id': {
      handler (val) {
        console.log('form.parent_id', val);
      }
    },
    'form.menu_type': {
      handler (val) {
        console.log('val', val);
        // 组件地址
        const component = this.findObject(this.formOp.column, 'component');
        // 是否缓存
        const is_cache = this.findObject(this.formOp.column, 'is_cache');
        // 是否外连接
        const is_frame = this.findObject(this.formOp.column, 'is_frame');
        // 菜单图标
        const icon = this.findObject(this.formOp.column, 'icon');
        // 路由地址
        const path = this.findObject(this.formOp.column, 'path');
        // 排序
        const sorting = this.findObject(this.formOp.column, 'sorting');
        // 显示
        const visible = this.findObject(this.formOp.column, 'visible');
        // 权限表示
        const perms = this.findObject(this.formOp.column, 'perms');
        // 资源
        const resource_id = this.findObject(this.formOp.column, 'resource_id');
        resource_id.display = false;

        visible.display = true;
        is_frame.display = false;
        sorting.display = true;
        path.display = false;
        perms.display = false;
        if (val === 'M') {
          component.display = false;
          is_cache.display = false;
          // menu_name.permss = [{
          //   required: true,
          //   message: '请输入内容2',
          //   trigger: 'blur'
          // }];
        } else if (val === 'C') {
          component.display = true;
          is_cache.display = true;
          is_frame.display = true;
          perms.display = true;
          resource_id.display = true;
          path.display = true;
        } else if (val === 'F') {
          icon.display = false;
          path.display = false;
          resource_id.display = true;
          perms.display = true;
          is_frame.display = false;
          sorting.display = false;
          visible.display = false;
        }
      }
    },
    filterText (val) {
      console.log(val);
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    submitAvueForm (e, done) {
      console.log('submitAvueForm', e);
      // this.$message.success('1s后关闭');
      // if (this.submittype === 0) {
      console.log('菜单id', e.menu_id);
      if (!e.menu_id) {
        e.menu_id = 0;
      }
      console.log('菜单id', e.menu_id);
      add.r(e).then((res) => {
        console.log('res', res);
        this.getList();
        done();
        this.form = {
          menu_name: '',
          path: '',
          perms: 'admin',
          resource_id: [],
          menu_id: 0,
          menu_type: 'M',
          parent_id: 0,
          is_cache: 1,
          status: 0,
          visible: 0,
          is_frame: 1
        };
      });
      // }
      // if (this.submittype === 1) {

      // }
      setTimeout(() => {
        done();
      }, 1000);
    },
    resetChange () {
      console.log('清!@!空');
      this.form = {
        menu_id: 0,
        menu_type: 'M',
        parent_id: 0,
        is_cache: 1,
        status: 0,
        visible: 0,
        is_frame: 1
      };
    },
    handleSuccess () {
      const data = this.formData;
      if (this.submittype === 0) {
        addMenu.r(data).then(res => {
          this.formData = {
            scenario: 'admin',
            sorting: 0,
            type: 1
          };
          this.$message.success(`菜单《${res.menu_name}》添加成功`);
          this.getTreeList();
        });
      } else {
        // updataMenu.r(data).then(res => {
        //   this.formData = {
        //     scenario: 'admin',
        //     sorting: 0,
        //     type: 1
        //   };
        //   this.$message.success(`菜单《${data.name}》修改成功`);
        //   this.getTreeList();
        //   this.submittype = 0;
        // });
      }
    },
    resetForm (e) {
      console.log('清空', e);
    },
    append (data) {
      const newChild = {
        id: '0',
        parent_id: data.id,
        scenario: data.scenario,
        display_name: '新增菜单',
        label: '新增菜单',
        subtitle: '',
        sorting: 0,
        type: 1,
        children: []
      };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      this.formData = newChild;
      data.children.push(newChild);
    },
    edit (node, data) {
      console.log(node, data);
      this.submittype = 1;
      // const { parent } = node;
      // const children ; // = parent.data ? (parent.data.children || parent.data ) : parent.data;
      const arr = [];
      for (let i = 0; i < data.resource_id.length; i++) {
        arr.push(+data.resource_id[i]);
      }
      data.name = data.label;
      data.resource_id = arr;
      this.form = data;
      console.log(this.form);
    },
    remove (node, data) {
      this.$confirm(`此操作将永久删除菜单《${data.menu_name}》, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(node, data);
        del.r({ menu_id: data.menu_id }).then((res) => {
          console.log('!@@@!', res);
          this.$message.success('菜单删除成功~');
          this.getList();
        });
      });
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleSubmit () {
      // data
      return Promise.resolve();
    },
    getTreeList () {
      getTree.r().then(res => {
        this.data = res;
      });
    },
    getList () {
      dataApi.getList.r().then(res => {
        this.dataDic = res.data;
        const resource_id = this.findObject(this.formOp.column, 'resource_id');
        resource_id.dicData = res.data;
        // res;
      });
      getList.r().then((res) => {
        this.data = res.data;
        const parent_id = this.findObject(this.formOp.column, 'parent_id');
        console.log(parent_id, 'parent_id');
        parent_id.dicData = res.data;
        // parent_id.value = 2014;
      });
    }
  }
};
