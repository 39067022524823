<template>
  <div class="page">
    <div class="page-title">
      <h4>菜单</h4>
    </div>
    <el-row :gutter="24">
      <el-col :span="6">
        <div class="treeDiv">
        <div class="input-nav">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText"
            size="small"
            class="filter"
          >
          <!-- <el-button slot="append" icon="el-icon-refresh" type="success" @click="getList()"></el-button> -->
          </el-input>
        <div class="refresh-icon"><el-link :underline="false" @click="getList()">
          <i style="font-size: 20px;" class="el-icon-refresh"/></el-link></div>
        </div>
          <el-tree
            :data="data"
            node-key="id"
            default-expand-all
            :props="defaultProps"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            ref="tree"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span>
                <el-button-group>
                  <!-- <el-button
                    icon="el-icon-circle-plus"
                    size="mini"
                    @click="() => append(data)"
                  >
                  </el-button> -->
                  <el-button icon="el-icon-edit" size="mini" @click="() => edit(node, data)">
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" @click="() => remove(node, data)">
                  </el-button>
                </el-button-group>
              </span>
            </span>
          </el-tree>
        </div>
      </el-col>

      <el-col :span="18">
        <!-- <avue-input-tree
        v-if="false"
          ref="tree"
          include-half-checked=""
          multiple
          :leafOnly="false"
          :checkStrictly="true"
          :props="{
              label: 'name',
              value: 'resource_id'
            }"
          v-model="formDic"
          placeholder="请选择内容"
          type="tree"
          :dic="dataDic"
        ></avue-input-tree> -->
        <avue-form
          ref="avueForm"
          :option="formOp"
          v-model="form"
          @submit="submitAvueForm"
          @reset-change="resetChange"
          @resetForm="resetForm"
        >
        </avue-form>
        <!--
        <div class="grid-content bg-purple">
          <ele-form
            :isShowBackBtn="false"
            :isShowCancelBtn="false"
            :isResponsive="false"
            :span="14"
            labelPosition="left"
            formBtnSize="small"
            :formDesc="formDesc"
            v-model="formData"
            :options-fn="http.get"
            :request-fn="handleSubmit"
            @request-success="handleSuccess"
          ></ele-form>
        </div>
         -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Menu from './menu';

export default Menu;
</script>
